/* Template Name: Upwind - Tailwind CSS Landing Page Template
   Version: 1.8.0
   Created: March 2022
   File: Main Css File
*/


@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.nav-item {
   cursor: pointer;
}